import React from "react"
import { ErrorResponse } from "../components/ErrorsVariants"
import { NewHeader } from "../components/Headers/NewHeader"
import Layout from "../components/Layouts/base"

export default function ErrorTechnical() {
  return (
    <div className="error-pages">
      <Layout>
        <NewHeader redLogoWithSovcombank withButton={false} noRightSection noShadow={false} />
        <div className="error-pages-wrapper">
          <ErrorResponse variant="technical" />
        </div>
      </Layout>
    </div>
  )
}
